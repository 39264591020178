type RefundModalCallback = (interventionId: string) => void | Promise<void>

export const useInterventionRefundModal = defineStore('intervention-refund-modal', () => {
  const currentId = ref<string>()
  const _invoiceId = ref<number>()
  const openListeners = new Set<RefundModalCallback>()
  const successListeners = new Set<RefundModalCallback>()
  const successOnceListeners = new Set<RefundModalCallback>()
  const cancelListeners = new Set<RefundModalCallback>()

  function open(
    interventionId: string | null | undefined,
    invoiceId: number | null | undefined,
    successCallback?: RefundModalCallback,
  ) {
    if (!interventionId || !invoiceId)
      return
    if (successCallback) {
      onceSuccess(successCallback)
    }
    currentId.value = interventionId
    _invoiceId.value = invoiceId
    openListeners.forEach(listener => listener(interventionId))
  }

  function success() {
    const id = currentId.value
    if (!id)
      return

    currentId.value = undefined
    successListeners.forEach(listener => listener(id))
    successOnceListeners.forEach(listener => listener(id))
    successOnceListeners.clear()
  }

  function cancel() {
    const id = currentId.value
    if (!id)
      return

    currentId.value = undefined
    cancelListeners.forEach(listener => listener(id))
  }

  function onceSuccess(callback: RefundModalCallback) {
    successOnceListeners.add(callback)

    function stop() {
      successOnceListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }

  function onSuccess(callback: RefundModalCallback) {
    successListeners.add(callback)

    function stop() {
      successListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }
  function onCancel(callback: RefundModalCallback) {
    cancelListeners.add(callback)

    function stop() {
      cancelListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }
  function onOpen(callback: RefundModalCallback) {
    openListeners.add(callback)

    function stop() {
      openListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }

  return {
    currentId: readonly(currentId),
    invoiceId: readonly(_invoiceId),
    open,
    success,
    cancel,

    onceSuccess,
    onOpen,
    onSuccess,
    onCancel,
  }
})
